import { LicenseService } from './../services/api/license.service';
import { Injectable } from '@angular/core';
import { ApiService } from './../services/api/api.service';
import { CanActivate, Router } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { of, throwError, Subject } from 'rxjs';

import { ModalMessages, ModalService, ModalType } from '../services/modal-service/modal.service';
import { ResourceService } from '../services/resources/resource.service';

import { ResourceObject } from '../services/resources/resource.service';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketService } from '../services/api/websocket.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  theme;
  loader$ = new Subject();
  constructor(
    private auth: ApiService,
    private licenseService: LicenseService,
    private router: Router,
    private modalService: ModalService,
    private resourceService: ResourceService,
    private translate: TranslateService,
    private socket: WebsocketService

  ) { }


  canActivate() {
    this.loader$.next(true);
    if (!this.auth.authenticationState.getValue()) {
      return this.auth.getResource().pipe(
        switchMap((resource: ResourceObject) => {
          this.resourceService.checkResource(resource);
          return this.resourceService.checkPlace(resource).pipe(
            switchMap(p => {
              if (resource.connectorId == null) {
                const a: ModalMessages = { message: this.translate.instant('MODAL.DEFAULTMESSAGE.NOCONNECTOR') };
                this.modalService.showModal(ModalType.ERROR, a);
                return throwError(false);
              }
              this.modalService.dismissModal();
              return this.auth.createSocketConnection().pipe(
                switchMap(() => {
                  const deviceModel = {
                    HardwareId: this.auth.deviceId.getValue(),
                    AppVersion: this.auth.softwareVersion.getValue()
                  };

                  this.auth.putDevice(deviceModel).toPromise();
                  this.loader$.next(false);
                  this.auth.authenticationState.next(true);
                  return this.auth.readDeviceLicense().pipe(
                    switchMap(li => {
                      this.licenseService.setLicense(li);
                      const license = li;
                      if (license.status === 404) {
                        const a: ModalMessages = { message: this.translate.instant('MODAL.DEFAULTMESSAGE.NOLICENSE') };
                        this.modalService.showModal(ModalType.ERROR, a);
                        this.auth.authenticationState.next(false);
                        this.router.navigate(['login']);
                        return of(false);
                      }
                      return of(true);
                    }),

                  );
                }),
              );
            }),
          );
        }),
        catchError(err => {

          this.auth.authenticationState.next(false);
          this.loader$.next(false);
          this.router.navigate(['login']);
          return of(false);
        })
      );
    } else {

      this.socket.licenseCallback.next(true);
      this.loader$.next(false);
      return true;
    }
  }
}

