import { WebsocketService } from './services/api/websocket.service';
import { switchMap, mergeMap, map, take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, ActivatedRoute } from '@angular/router';

import { ApiService } from './services/api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language-service/language.service';
import { StorageService } from './services/storage-service/storage-service.service';
import { Storage } from '@ionic/storage';
import { LicenseService } from './services/api/license.service';
import { of, Subscription, timer } from 'rxjs';
import { ModalService, ModalType } from './services/modal-service/modal.service';
import { NetworkserviceService } from './services/Networkservice/networkservice.service';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { ResourceService } from './services/resources/resource.service';
import { AuthGuard } from './guards/auth.guard';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  appVersion = '1.0.0';
  pages: Array<{ title: string, url: any, icon: string, lockIcon: boolean }>;
  activePage = '';
  theme: string;
  private settingsLoaded = false;
  private licensesLoaded = false;
  private subscription: Subscription;
  roomName;
  locale: string;
  logoutLock = false;
  private reconnectRunning = false;
  public eventSubscription: Subscription;
  public licenseSubscription: Subscription;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private api: ApiService,
    private licenseService: LicenseService,
    public translate: TranslateService,
    private language: LanguageService,
    public storageService: StorageService,
    private storage: Storage,
    private socket: WebsocketService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private insomnia: Insomnia,
    private NetworkService: NetworkserviceService,
    private version: AppVersion,
    private resourceService: ResourceService,
    public authGuard: AuthGuard


  ) {
    this.initializeApp();

  }

  ngOnInit() {
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.hide();
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);
      this.insomnia.keepAwake().then();
      if (this.platform.is('cordova')) {
        this.version.getVersionNumber().then(version => {
          if (version !== null) {
            this.api.softwareVersion.next(version);
          } else {
            this.api.softwareVersion.next(this.appVersion);
          }
        });
      } else {
        this.api.softwareVersion.next(this.appVersion);
      }
      this.language.setInitLang();
      this.storageService.iniAvatarImg();

      /*Start event listening*/
      this.startEventListening();

      /*Start resource listening changes*/
      this.resourceService.startResourceListener().subscribe(res => {
        if (!res) {
          this.modalService.isModalOpen().then(v => v ? this.modalService.dismissModal().then(x => {
            if (x) {
              this.modalService.showModal(ModalType.NOPLACE);
            }
          }).catch(_ => { }) : this.modalService.showModal(ModalType.NOPLACE));
          this.clearEvents();
        } else {

          this.modalService.dismissModal();

          // License Checking

          if (this.licenseService.license.getValue() != null) {
            this.licenseService.checkingLicense(this.licenseService.license.getValue()).toPromise();
          }
          if (this.licenseService.isValid() && this.eventSubscription.closed) {
            this.startEventListening();
          }
        }
      });


      this.licenseSubscription = this.licenseService.licenseListener().subscribe(res => {
        if (!res) {

          this.clearEvents();
        }
        if (res) {
          if (this.licenseService.isValid() && this.eventSubscription.closed) {
            if (this.eventSubscription.closed) {

              this.startEventListening();
            }
          }
        }
      });



      /*Remove Connector Callback */
      this.socket.removeConnectorCallback.subscribe(r => {
        if (r) {
          this.clearEvents();
          this.modalService.isModalOpen().then(v => v ? this.modalService.dismissModal().then(x => {
            if (x) {

              this.modalService.showModal(ModalType.NOCONNECTOR);
            }
          }).catch(_ => { }) : this.modalService.showModal(ModalType.NOCONNECTOR));
          this.socket.connectionCloseWithReconnect();
        }

        if (!r) {
        }
      });

      /*Reconnect Socket if undefined status of connection */
      this.socket.socketStatus.pipe(
        mergeMap(() => {

          if (!this.reconnectRunning) {
            this.reconnectRunning = true;
            let time = Math.floor(Math.random() * (60 - 5 + 1) + 5);
            time = (time * 1000);

            return timer(time).pipe(switchMap(() => {
              this.reconnectRunning = false;
              this.api.readDeviceLicense().pipe(take(1)).subscribe(li => {
                this.licenseService.checkingLicense(li).toPromise();
              });


              return this.api.createSocketConnection();
            }));
          }
          return of(true);
        })
      ).subscribe();

      /*Wipe device Callback */
      this.socket.removeCallback.subscribe(() => {

        this.licenseSubscription.unsubscribe();
        this.socket.connectionClose();
        this.modalService.isModalOpen().then(v => v ? this.modalService.dismissModal().then().catch(_ => { }) : null);
        this.api.authenticationState.next(false);
        this.storage.clear();
        this.api.deviceId.next(undefined);
        this.router.navigateByUrl('/login');
      });

    });




  }


  // Helper

  startEventListening() {

    this.eventSubscription = this.resourceService.startEventListener().subscribe();

  }

  clearEvents() {
    const re = this.resourceService.resource.getValue();
    re.rooms = [];
    this.resourceService.resource.next(re);
  }
}
