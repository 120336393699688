import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DateTime, TimeSpan } from 'ews-js-api-browser';
import { WebsocketService } from '../api/websocket.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../language-service/language.service';

import { formatDate } from '@angular/common';



@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  private currentDay = DateTime.Now.Today;
  public clock;
  public date;
  public resource: BehaviorSubject<ResourceObject> = new BehaviorSubject(undefined);
  public routput;
  public resourceSettings: BehaviorSubject<RoomBoardSettings> = new BehaviorSubject(undefined);
  public events = new BehaviorSubject<any[]>([]);
  public avatar = new BehaviorSubject<any>('./assets/img/logo.png');


  constructor(
    private socket: WebsocketService,
    private translate: TranslateService,
    private language: LanguageService,

  ) {
    this.startClock();
  }


  startEventListener() {
    return this.socket.eventsCallback.pipe(switchMap(rooms => {
      console.log(rooms);
      const a: ResourceObject = this.eventMapper(rooms, this.resource.getValue());
      this.resource.next(a);
      return of(true);
    }));
  }

  startResourceListener() {
    return this.socket.resourceCallback.pipe(
      switchMap((resource) => {
        this.checkResource(resource);
        if (resource.connectorId != null) {
          this.socket.removeConnectorCallback.next(false);
        }
        return of(true);
      })
    );
  }


  checkResource(resource: ResourceObject) {
    if (resource.avatar != null) {
      this.avatarBlobber(resource.avatar);
    }
    this.language.setLanguage(resource.language);
    this.resourceSettings.next(resource.settings);

    this.resource.next(resource);

  }




  eventMapper(list1: Array<any>, list2: any) {
    list2.rooms.forEach((r: any) => {
      const find = list1.find(room => room._id == r._id);
      if (find != undefined) {
        r.events = find.events;
        if (r.events.length > 0 && r.events[1] !== undefined) {
          let nextSubject = r.events[0].nextSubject != null ? r.events[0].nextSubject : this.translate.instant('MAIN_VIEW.NOSUBJECT');
          nextSubject += ' ' + this.translate.instant('MAIN_VIEW.AT') + ' ' + formatDate(r.events[1].start, 'shortTime', this.language.selected.getValue());
          r.events[0].nextSubject = nextSubject;
        }
      } else {
        r.events = [];
      }
    });
    return list2;
  }

  checkPlace(resource: ResourceObject) {
    if (resource.connectorId == null || resource.connectorId == undefined) {
      return of(true);
    }
    if (resource.rooms.length == null || resource.rooms.length == undefined) {
      return of(false);
    } else {
      return of(true);
    }
  }

  // Helper

  startClock() {

    setInterval(() => {
      this.clock = new Date();
      this.date = new Date();
    }, 1000);
  }

  avatarBlobber(avatar) {
    const img = avatar;
    fetch(avatar).then(r =>
      r.blob().then(b => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(b);
        fileReader.onload = ev => {
          this.avatar.next(ev.target.result);
        };
      }));
  }
}



// ###### Interfaces ####### //

export interface ResourceObject {
  address: {
    city: string;
    countryOrRegion: string;
    postalCode: string;
    state: string;
    street: string;
  };
  rooms: Array<any>;
  avatar: string;
  building: string;
  connectorId: string;
  language: string;
  devices: Array<Room>;
  displayName: string;
  floorLabel: string;
  floorNumber: string;
  licenseNumber: string;
  organizationId: string;
  resourceAccount: string;
  viewLink: string;
  settings: RoomBoardSettings;
  _id: string;
  _t: string;
}


export interface RoomBoardSettings {
  gpdrPrivacy: boolean;
  transitionTime: number;
}

export interface Room {
  adress: {
    city: string;
    countryOrRegion: string;
    postalCode: string;
    state: string;
    street: string;
  };
  displayName: string;
  events: Array<any>;
  resourceAccount: string;
  _id: string;

}

export interface RoomBoardEvent {
  start: DateTime;
  end: DateTime;
  subject: string;
  nextSubject: string;
  isNext: boolean;
}

export interface EventObject {
  activeStatus: number;
  autoCancelTime: DateTime;
  checkedIn: boolean;
  end: any;
  duration: TimeSpan;
  id: string;
  isAllDay: boolean;
  members: string[];
  organizer: string;
  start: DateTime;
  subject: string;
  nextDay: boolean;
}
