import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, from } from 'rxjs';

import { ApiService } from '../api/api.service';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { Device } from '@ionic-native/device/ngx';


export interface SettingsInterface {
  confirmPassword: any;
  futureDays: number;
  maxDisplayedEvents: number;
  quickEventName: string;
  roomName: string;
  scheduledEventName: string;
  activateMeetingTitles: boolean;
  appMeetingTitles: Array<string>;
  settingsPassword: any;
  syncIntervall: number;
  theme: string;
  checkIn: boolean;
  checkOut: boolean;
  preCheck: boolean;
  autoCancel: boolean;
  preCheckInTime: number;
  cancelTime: number;
  userMail: string;
  roomMail: string;
  password: string;
  serverUrl: string;
  mailboxType: number;
}

export class Settings implements SettingsInterface {
  confirmPassword = null;
  futureDays = 8;
  maxDisplayedEvents = 5;
  quickEventName = 'HXA Quick Event';
  roomName = 'HXA Room Calendar';
  scheduledEventName = 'HXA Scheduled Event';
  activateMeetingTitles = false;
  appMeetingTitles = [];
  settingsPassword = null;
  syncIntervall = 30;
  theme = 'modern';
  checkIn = false;
  checkOut = false;
  preCheck = false;
  autoCancel = false;
  preCheckInTime = 5;
  cancelTime = 5;
  userMail = null;
  roomMail = null;
  password = null;
  serverUrl = null;
  mailboxType = 0;
}

export class AppSettings {
  public static defaultImage = './assets/img/logo.png';
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {



  public IMAGE = new BehaviorSubject(AppSettings.defaultImage);

  defaultsettings = new Settings();
  settings = new BehaviorSubject<SettingsInterface>(undefined);
  ewsAccount = new BehaviorSubject<SettingsInterface>(undefined);
  observable = new Observable();

  settingsPassword = new BehaviorSubject<any>(null);
  constructor(private storage: Storage, private apiService: ApiService, private device: Device) {

  }


  public getSetting(): Observable<any> {

    return this.apiService.getSettings().pipe(
      map((settings): SettingsInterface => {
        if (settings) {

          this.settings.next(settings);

          return settings;
        }
      })
    );

  }


  updateSocketSettings() {
    return this.apiService.getSettings().pipe(
      map((settings): SettingsInterface => {
        return settings;
      }
      ),
    );
  }


  saveSettings(value): Observable<any> {
    this.apiService.postSettings(value).pipe(
      map(_ => {
        return;
      }
      ));
    return;
  }

  getAccount(): Observable<any> {
    return this.apiService.getAccount().pipe(
      distinctUntilChanged(),
      map(account => {
        if (account) {
          this.ewsAccount.next(account);

          return account;
        }
      })
    );
  }


  updateSocketAccount() {
    return this.apiService.getAccount().pipe(
      map((account) => {
        return account;
      }),
    );
  }


  getDefaultSettings() {
    this.settings.next(this.defaultsettings);
  }

  setDefault() {
    this.settings.next(this.defaultsettings);
  }


  getSettingsPassword() {
    return this.settings.getValue().settingsPassword;
  }

  setAvatarImg(img) {
    this.storage.set('avatar', img);
  }


  removeImg() {
    this.IMAGE.next(AppSettings.defaultImage);
    this.storage.remove('avatar');
  }

  iniAvatarImg() {
    this.storage.get('avatar').then(img => {
      if (img) {
        this.IMAGE.next(img);
      } else {
        this.IMAGE.next(AppSettings.defaultImage);
      }
    }
    );
  }


  /** Account Helper */

  isEWSLoggedIn() {
    if (this.ewsAccount.getValue().userMail && this.ewsAccount.getValue().password) {
      return true;
    }
    return false;
  }


  // Serial Helper
  getSerialNumber(): Observable<any> {
    return from(this.storage.get('serialNo').then(serial => {
      if (serial == null) {
        return null;
      } else {
        return serial;
      }
    }));
  }
}



