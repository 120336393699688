import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap, switchMapTo, take } from 'rxjs/operators';
import { WebsocketService } from './websocket.service';
import { ModalService, ModalType } from '../modal-service/modal.service';
import { Settings, StorageService } from '../storage-service/storage-service.service';
import { ResourceObject, ResourceService } from '../resources/resource.service';


@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  private licenseObj: License;
  private timeout: any;
  public license: BehaviorSubject<License> = new BehaviorSubject<License>(null);
  public isInit = true;
  public subscription: Subscription = new Subscription();
  constructor(
    private api: ApiService,
    private socket: WebsocketService,
    private modalService: ModalService,
    private resourceService: ResourceService) {
  }



  licenseListener() {
    return this.socket.licenseCallback.pipe(
      switchMap(() => {
        if (this.license.getValue() === undefined) {
          this.modalService.isModalOpen().then(v => v ? this.modalService.dismissModal() : null);
        }
        return this.api.readDeviceLicense().pipe(
          switchMap(license => {
            return this.checkingLicense(license).pipe();
          }),
          catchError((err) => {
            this.modalService.showModal(ModalType.NOLICENSE).catch(_ => { });
            this.license.next(undefined);
            return of(false);
          })
        );
      })
    );
  }

  checkingLicense(li): Observable<any> {
    this.setLicense(li);
    if (li.status === 404) {
      this.modalService.showModal(ModalType.NOLICENSE).catch(_ => { });
      this.license.next(undefined);
      return of(false);
    }

    if (this.isValid()) {
      this.api.getResource().pipe().toPromise().then((r: ResourceObject) => {
        this.resourceService.resourceSettings.next(r.settings);
        if (r.avatar != null) {
          this.resourceService.avatar.next(r.avatar);
        }
      });
    }
    this.modalService.dismissModal();
    return of(true);
  }


  public setLicense(li: License) {
    this.licenseObj = li;
    this.license.next(li);
  }

  public isValid() {
    if (this.licenseObj != null) {
      return true;
    }
    return false;
  }

  public isTrial() {
    if (this.licenseObj.licenseType === 'Trial7Days') {
      return true;
    }
    return false;
  }

  public isFree() {
    if (this.licenseObj != null && this.licenseObj.licenseType === 'Free') {
      return true;
    }
    return false;
  }

  public getExpireDate() {
    if (this.license != null) {
      return this.licenseObj.expireDate;
    }
    return '';
  }

  public getDuration() {
    const date = Date.now();
    const expire = new Date(this.licenseObj.expireDate);
    const duration = expire.valueOf() - date.valueOf();
    return Math.ceil(duration / (1000 * 3600 * 24));
  }
}
export interface License {
  _id: string;
  userId: string;
  type: string;
  licenseType: string;
  licenseNumber: string;
  deviceId: string;
  activateDate: string;
  expireDate: string;
  tokenId: string;
  status: number;
}

